import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';

import reducer from './reducers';
import initialState from './initial-state';
import persistConfig from './persistConfig';

const middleware = [thunk];
const enhancers = [];
const windowExists = typeof window === 'object';
const composeEnhancers = windowExists && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose;

const persistedReducer = persistReducer(persistConfig, reducer);

export default () => {
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(
      applyMiddleware(...middleware),
      ...enhancers,
    ),
  );

  const persistor = persistStore(store);

  return { store, persistor };
};
