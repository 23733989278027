import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { store, persistor } from './src/state/index.js';
import NetworkDetector from './src/components/NetworkDetector';
import theme from './theme';

export const wrapWithHocsClient = ({ element }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {element}
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

export const wrapWithHocsServer = ({ element }) => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <NetworkDetector />
      <CssBaseline />
      {element}
    </ThemeProvider>
  </Provider>
);
