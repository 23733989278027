import storage from 'redux-persist/lib/storage';
import { createMigrate } from 'redux-persist';

import initialState from './initial-state';

const migrations = {
  0: () => initialState,
  1: () => initialState,
};

export default {
  key: 'root',
  version: 1,
  storage,
  blacklist: [],
  migrate: createMigrate(migrations, { debug: false }),
};
