import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setConnectionStatus } from '../state/actions/app';

class NetworkDetector extends PureComponent {
  componentDidMount() {
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleConnectionChange);
    window.removeEventListener('offline', this.handleConnectionChange);
  }

  handleConnectionChange = () => {
    const { setConnection } = this.props;
    const condition = navigator.onLine ? 'online' : 'offline';

    if (condition === 'online') {
      const webPing = setInterval(
        () => {
          fetch('https://google.com', {
            mode: 'no-cors',
          })
            .then(() => {
              setConnection(true);
              clearInterval(webPing);
            })
            .catch(() => setConnection(false));
        }, 2000,
      );
      return;
    }

    setConnection(false);
  }

  render() {
    return <div />;
  }
}

NetworkDetector.propTypes = {
  setConnection: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  setConnection: bool => dispatch(setConnectionStatus(bool)),
});

export default connect(null, mapDispatchToProps)(NetworkDetector);
