import initialState from '../initial-state';
import { SET_CONNECTION_STATUS } from '../actionTypes';

export default function appReducer(state = initialState.app, { type, payload }) {
  switch (type) {
    case SET_CONNECTION_STATUS:
      return { ...state, hasInternet: payload };
    default:
      return state;
  }
}
