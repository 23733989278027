import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#7cc9f8',
      main: '#4CB5F5',
      contrastText: '#fff',
    },
    secondary: {
      main: '#8571b2',
      contrastText: '#fff',
    },
    error: {
      main: red.A400,
      contrastText: '#fff',
    },
    white: {
      main: '#fff',
    },
  },
});

export default theme;
